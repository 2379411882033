import { createAction, props } from '@ngrx/store';

export enum AppConfigurationsActionsTypes {
  Initialize = '[APP CONFIGURATIONS] Initialize',
  ToggleNavCollapsed = '[APP CONFIGURATIONS] TOGGLE NAV COLLAPSED',
  SetNavCollapsed = '[APP CONFIGURATIONS] SET NAV COLLAPSED',
  SignedUpSellerWaitlist = '[APP CONFIGURATIONS] SIGNED UP SELLER WAITLIST',
  SignedUpSellerWaitlistSuccess = '[APP CONFIGURATIONS] SIGNED UP SELLER WAITLIST SUCCESS',
}

export const initialize = createAction(
  AppConfigurationsActionsTypes.Initialize,
  props<{ navCollapsed: boolean }>(),
);

export const sellerWaitlistSignup = createAction(
  AppConfigurationsActionsTypes.SignedUpSellerWaitlist,
);

export const sellerWaitlistSignupSuccess = createAction(
  AppConfigurationsActionsTypes.SignedUpSellerWaitlistSuccess,
);

export const toggleNavCollapsed = createAction(
  AppConfigurationsActionsTypes.ToggleNavCollapsed,
);

export const setNavCollapsed = createAction(
  AppConfigurationsActionsTypes.SetNavCollapsed,
  props<{ navCollapsed: boolean }>(),
);
