import { Router, UrlTree } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { defer, iif, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthSharedService, selectUser } from '@chassis/auth/shared';
import { SharedRoutes } from '@chassis/shared/routes';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginGuard {
  readonly router = inject(Router);
  readonly store = inject(Store);
  readonly authSharedService = inject(AuthSharedService);

  canActivate(): Observable<boolean | UrlTree> {
    return of(this.authSharedService.getCookie()).pipe(
      switchMap((token) =>
        iif(
          () => !!token,
          defer(() =>
            this.store.select(selectUser).pipe(
              switchMap((user) =>
                iif(
                  () => !!user?.id,
                  defer(() => of(true)),
                  defer(() => of(this.router.parseUrl(SharedRoutes.authLogin))),
                ),
              ),
            ),
          ),
          defer(() => of(this.router.parseUrl(SharedRoutes.authLogin))),
        ),
      ),
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
