import { Router, UrlTree } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { iif, map, Observable, of, switchMap } from 'rxjs';
import { AuthSharedService } from '@chassis/auth/shared';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class UnAuthGuard {
  readonly router = inject(Router);
  readonly authSharedService = inject(AuthSharedService);
  readonly service = inject(NavigationService);

  canActivate(): Observable<boolean | UrlTree> {
    return of(this.authSharedService.getCookie()).pipe(
      switchMap((token) =>
        iif(
          () => !token,
          of(true),
          this.service
            .getDefaultRoute()
            .pipe(map((defaultRoute) => this.router.parseUrl(defaultRoute))),
        ),
      ),
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
