import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { defer, iif, map, Observable, of, switchMap, tap } from 'rxjs';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  readonly router = inject(Router);
  readonly service = inject(NavigationService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.service.canAccessRoute(route, state).pipe(
      switchMap((canAccess) =>
        iif(
          () => canAccess,
          defer(() => of(true)),
          defer(() =>
            this.service
              .getDefaultRoute()
              .pipe(map((defaultRoute) => this.router.parseUrl(defaultRoute))),
          ),
        ),
      ),
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}
