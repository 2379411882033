import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageService } from '@chassis/shared/services/storage';
import { UserRole, Yms } from '@chassis/shared/models';
import { combineLatest, first, map, Observable, of, take, tap } from 'rxjs';
import { selectUser } from '@chassis/auth/shared';
import { selectYardConfig } from '@chassis/yard-management';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LaunchDarklyService } from '@chassis/shared/services/launch-darkly';
import { BuyerRoutes } from '@chassis/buyer/shared';
import {
  AllRoutesRolesByRouteAndYms,
  SharedRoutes,
} from '@chassis/shared/routes';
import { SellerRoutes } from '@chassis/seller/shared';
import { intersection } from 'lodash-es';
export const ACTIVE_ROUTE = 'active-route';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly store = inject(Store);
  readonly localStorageService = inject(StorageService);
  readonly launchDarklyService = inject(LaunchDarklyService);
  readonly ignoreRoutes: string[] = Object.values(SharedRoutes).map((i) =>
    i.replace(/^\//, ''),
  );

  persistRoute(url: string) {
    const cleanUrl = url?.replace(/^\/+/, '');
    if (this.canPersist(cleanUrl)) {
      this.setPersistedRoute(cleanUrl.split('/'));
    }
  }

  getDefaultRoute(): Observable<string> {
    return this.store.select(selectUser).pipe(
      first((user) => !!user.id),
      map((user) => {
        const lastRoute = this.getPersistedRoute();

        if (lastRoute?.length) {
          return lastRoute.join('/');
        }

        const { roles } = user;
        if (roles?.includes(UserRole.buyer)) {
          return BuyerRoutes.search;
        }

        if (roles?.includes(UserRole.billing_admin)) {
          return BuyerRoutes.billingPortal;
        }

        if (roles?.includes(UserRole.messaging)) {
          return BuyerRoutes.messenger;
        }

        if (roles?.includes(UserRole.seller)) {
          return SellerRoutes.dashboard;
        }

        return SharedRoutes.permissionDenied;
      }),
    );
  }

  canAccessRoute(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return combineLatest([
      this.routeFeatureFlag(route),
      this.store.select(selectUser).pipe(first((user) => !!user.id)),
      this.store.select(selectYardConfig).pipe(first(({ yms }) => !!yms)),
    ]).pipe(
      map(([featureEnabled, user, { yms }]) => {
        if (!featureEnabled) {
          return false;
        }

        return this.canRoute(state, yms, user.roles);
      }),
    );
  }

  private getPersistedRoute(): string[] {
    const lastRouteStr = this.localStorageService.getItem(ACTIVE_ROUTE);
    if (!lastRouteStr) {
      return [];
    }
    return JSON.parse(lastRouteStr) as string[];
  }

  private setPersistedRoute(route: string[]): any {
    this.localStorageService.setItem(ACTIVE_ROUTE, JSON.stringify(route));
  }

  private canPersist(url: string): boolean {
    if (!url?.length) {
      return false;
    }
    return !this.ignoreRoutes.includes(url);
  }

  private routeFeatureFlag(route: ActivatedRouteSnapshot): Observable<boolean> {
    const routeFlag = route.data['flag'];
    return this.launchDarklyService.flag$(routeFlag, true);
  }

  private canRoute(
    state: RouterStateSnapshot,
    yms: Yms,
    userRoles: UserRole[],
  ) {
    try {
      const url = state.url.split('?')[0];

      const canAccess = intersection(
        AllRoutesRolesByRouteAndYms[url][yms],
        userRoles,
      );
      return canAccess.length > 0;
    } catch (e) {
      return false;
    }
  }
}
